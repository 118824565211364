<template>
  <el-container>
    <el-row style="width: 100%">
      <el-col :span="24">
        <div class="cardTitle">复议列表</div>
      </el-col>
      <el-col :span="24">
         <el-table :data="userList" :default-sort = "{prop: 'districtName', order: 'desc'}" style="width: 100%">
                <el-table-column prop="districtName" label="区" sortable></el-table-column>
                <el-table-column prop="streetName" label="街道" sortable></el-table-column>
                <el-table-column prop="communityName" label="小区" sortable></el-table-column>
                <el-table-column prop="surveyName" label="问卷题目" sortable></el-table-column>
                <el-table-column prop="questionTitle" label="问题题目" sortable></el-table-column>
                <el-table-column prop="createByName" label="提交人" sortable></el-table-column>
                <el-table-column prop="createTime" label="提交时间" sortable></el-table-column>
                <el-table-column fixed="right" label="操作">
                  <template slot-scope="scope">
                    <el-button
                      @click="getDetail(scope.row)"
                      type="primary"
                      size="mini"
                    >
                      编辑
                    </el-button>
                  </template>
                </el-table-column>
              </el-table>
      </el-col>
   <el-col :span="24">
          <div class="block" style="margin-top: 20px">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="recordList.nowPageNum"
              :page-sizes="[ 20, 40, 80]"
              :page-size="pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="recordList.totalNum"
            >
            </el-pagination>
          </div>
        </el-col>
    </el-row>
  </el-container>
</template>

<script>
export default {
  data() {
    return {
      userInfo: {},
      userList:[],
      recordList:{},
      pageSize: 20,
    };
  },
  mounted() {
    let self = this;
    self.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    self.getReconsiderList(1)
  },
  methods: {
    getReconsiderList(pageNum) {
      let self = this;
      self.$axios
        .get("/reconsider-history/list/unCheck?pageSize=" +self.pageSize +"&pageNum=" +(pageNum || 1), {
          headers: { token: self.userInfo.token },
        })
        .then(function (response) {
          self.userList = response.data.data.records;
          self.recordList = response.data.data;
        })
        .catch(function (error) {
          self.$message({
            message: "复议待处理项列表获取失败："+error.response.data.message,
            type: "warning",
          });
        });
    },
    getDetail(row) {
      let self = this;
      self.$router.push({ name: "patrol_survey_reconsider_detail", query: { guid: row.guid } });
    },
     handleSizeChange(val) {
      this.pageSize = val * 1;
      this.getUserPageNum()
    },
    handleCurrentChange(val) {
      this.getUserPageNum(val)
    },
    getUserPageNum(val) {
      this.getReconsiderList(val);
    },
  },
};
</script>

<style scoped>
</style>